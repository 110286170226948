import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Availability } from './availability.model';
import { environment } from 'environments/environment';

@Injectable()
export class CalendarService
{
    apiUrl = environment.api;
    events: any;
    onEventsUpdated: Subject<any>;

    reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    constructor(private _httpClient: HttpClient)
    {
        // Set the defaults
        this.onEventsUpdated = new Subject();
    }

    getAvailabilities(id: number): Observable<any>
    {
        return this._httpClient.get(`${this.apiUrl}/nurse/${id}/availabilities`, {headers: this.reqHeader});
    }

    getAvailabilitiesByDate(id: number, date: any): Observable<any>
    {
        return this._httpClient.post(`${this.apiUrl}/nurse/${id}/availabilities/date`, {selectedDate: date}, {headers: this.reqHeader});
    }

    saveAvailability(id: number, availability: Availability): Observable<any> {
        return this._httpClient.post(`${this.apiUrl}/nurse/${id}/availability`, availability, {headers: this.reqHeader});
    }

    getEvents(): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/calendar/events')
                .subscribe((response: any) => {
                    this.events = response.data;
                    this.onEventsUpdated.next(this.events);
                    resolve(this.events);
                }, reject);
        });
    }

    updateEvents(events): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/calendar/events', {
                id  : 'events',
                data: [...events]
            })
                .subscribe((response: any) => {
                    this.getEvents();
                }, reject);
        });
    }

}
