import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PrescriptionService } from '../service/prescription.service';
import { Prescription, PrescriptionStatus } from '../model/prescription.model';
import { Patient } from '../model/patient.model';
import { Appointment, AppointmentStatus } from '../model/appointment.model';

@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.scss']
})
export class PrescriptionDetailComponent implements OnInit {
  dialogTitle = 'Prescription Detail';
  prescription: Prescription;
  patient: Patient;
  appointment: Appointment;
  loading: boolean;
  constructor(private _matDialogRef: MatDialogRef<PrescriptionDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _prescriptionService: PrescriptionService,
              private _matSnackBar: MatSnackBar) {
    this.prescription = _data.prescription;
  }

  ngOnInit(): void {
    if (this.prescription){
      this.loading = true;
      this._prescriptionService.getPresriptionDetail(this.prescription.id)
        .subscribe((data: any) => {
          this.prescription = data.prescription;
          this.patient = data.patient;
          this.appointment = data.appointment;
          this.loading = false;
        });
    }
  }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

  onClickSubmitButton(): void {
    if (this.prescription){
      this.prescription.completedByNurse = true;
      this.prescription.prescriptionStatus = PrescriptionStatus.completedByNurse;
      this._prescriptionService.savePrescription(this.prescription)
      .subscribe((data: any) => {
        this.prescription = data.prescription;
        this._prescriptionService.sendPrescriptionSubmissionEmailToAdmin(this.prescription.id)
          .subscribe((result: any) => {
            this.closeDialoag();
            this._matSnackBar.open('An email has been sent to Admin for approval of this prescription', null, {duration: 5000});
          });
      }, error => console.log(error));
    }
  }

  getAppointmentStatus(appointmentStatus: any): string{
    switch (appointmentStatus) {
      case AppointmentStatus.pending:
        return 'Pending';
      case AppointmentStatus.completed:
        return 'Completed';
      case AppointmentStatus.cancelled:
        return 'Cancelled';
      case AppointmentStatus.missed:
        return 'Missed';
      default:
        return '';
    }
  }

}
