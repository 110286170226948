import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    MatButtonModule, MatChipsModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatRippleModule, MatSelectModule, MatSnackBarModule,
    MatSortModule,
    MatTableModule, MatTabsModule, MatDatepickerModule, MatCardModule, MatListModule, MatToolbarModule, MatCheckboxModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { JoinConsultationComponent } from './join-consultation/join-consultation.component';
import { PendingPrescriptionComponent } from './pending-prescription/pending-prescription.component';
import { FinishedPrescriptionComponent } from './finished-prescription/finished-prescription.component';
import { PrescriptionService } from './service/prescription.service';
import { PrescriptionDetailComponent } from './prescription-detail/prescription-detail.component';
import { UpcomingAppointmentComponent } from './upcoming-appointment/upcoming-appointment.component';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { AppointmentService } from './service/appointment.service';
import { PatientDetailComponent } from './join-consultation/patient-detail/patient-detail.component';
import { PatientService } from './service/patient.service';

const routes: Routes = [
    {
        path     : 'book-appointment',
        component: BookAppointmentComponent
    },
    {
        path     : 'join-consultation',
        component: JoinConsultationComponent
    },
    {
        path     : 'pending-prescription',
        component: PendingPrescriptionComponent
    },
    {
        path     : 'finished-prescription',
        component: FinishedPrescriptionComponent
    },
    {
        path     : 'upcoming-appointment',
        component: UpcomingAppointmentComponent
    }

];

@NgModule({
    declarations: [
        BookAppointmentComponent,
        JoinConsultationComponent,
        PendingPrescriptionComponent,
        FinishedPrescriptionComponent,
        PrescriptionDetailComponent,
        UpcomingAppointmentComponent,
        AppointmentDetailComponent,
        PatientDetailComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCardModule,
        MatListModule,
        MatToolbarModule,
        MatCheckboxModule,

        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule
    ],
    providers   : [
        PrescriptionService,
        AppointmentService,
        PatientService
    ],
    entryComponents: [
        PrescriptionDetailComponent,
        AppointmentDetailComponent,
        PatientDetailComponent
    ]
})
export class EcommerceModule
{
}
