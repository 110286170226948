import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CalendarEvent } from 'angular-calendar';

import { MatColors } from '@fuse/mat-colors';

import { CalendarEventModel } from 'app/main/calendar/event.model';
import { Availability } from '../availability.model';
import { CalendarService } from '../calendar.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector     : 'calendar-event-form-dialog',
    templateUrl  : './event-form.component.html',
    styleUrls    : ['./event-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CalendarEventFormDialogComponent implements OnInit
{
    action: string;
    event: CalendarEvent;
    eventForm: FormGroup;
    dialogTitle: string;
    presetColors = MatColors.presets;
    nurseId: number;
    selectedDate: any;
    markAll: boolean;

    availability: Availability = new Availability();

    constructor(public matDialogRef: MatDialogRef<CalendarEventFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _formBuilder: FormBuilder,
                private _calendarService: CalendarService,
                private _activedRoute: ActivatedRoute,
                private _matSnackBar: MatSnackBar) {
        this.event = _data.event;
        this.action = _data.action;
        this.nurseId = _data.nurseId;
        this.selectedDate = _data.date;
        this.dialogTitle = 'Please Mark your available time slots';

        this.availability.nurseId = this.nurseId;
        this.availability.availableDate = this.selectedDate;
        this.availability.availableTimeSlot = this.createAllDayTimeSlots();

        if ( this.action === 'edit' )
        {
            // this.dialogTitle = this.event.title;
        }
        else
        {
            // this.dialogTitle = 'New Event';
            this.event = new CalendarEventModel({
                start: _data.date,
                end  : _data.date
            });
        }

        // this.eventForm = this.createEventForm();
    }

    ngOnInit(): void {
        this._calendarService.getAvailabilitiesByDate(this.nurseId, this.selectedDate)
            .subscribe((data: any) => {
                const availabltTimeSlots = this.createAllDayTimeSlots();
                if (data.error && data.error === 'no_availability_found'){
                    this.availability.availableTimeSlot = availabltTimeSlots;
                    this.availability.markedAsAvailable = false;
                    this.markAll = false;
                } else {
                    this.markAll = true;
                    Object.keys(availabltTimeSlots).map((key) => availabltTimeSlots[key] = data.availability.availableTimeSlot[key]);
                    this.availability.availableTimeSlot = availabltTimeSlots;
                    Object.keys(this.availability.availableTimeSlot).map((key) => {
                        if (!this.availability.availableTimeSlot[key]) {
                            this.markAll = false;
                            return;
                        }
                    });
                }
            }, error => console.log(error));
    }

    onClickMarkAll(): void {
        if (this.markAll){
            Object.keys(this.availability.availableTimeSlot).map((key, index) => {
                this.availability.availableTimeSlot[key] = true;
            });
        } else {
            Object.keys(this.availability.availableTimeSlot).map((key, index) => {
                this.availability.availableTimeSlot[key] = false;
            });
        }
    }

    private createAllDayTimeSlots(): any{
        const timeSlots = {
            '12 AM - 12.30 AM': false,
            '12.30 AM - 1 AM': false,
            '1 AM - 1.30 AM': false,
            '1.30 AM - 2 AM': false,
            '2 AM - 2.30 AM': false,
            '2.30 AM - 3 AM': false,
            '3 AM - 3.30 AM': false,
            '3.30 AM - 4 AM': false,
            '4 AM - 4.30 AM': false,
            '4.30 AM - 5 AM': false,
            '5 AM - 5.30 AM': false,
            '5.30 AM - 6 AM': false,
            '6 AM - 6.30 AM': false,
            '6.30 AM - 7 AM': false,
            '7 AM - 7.30 AM': false,
            '7.30 AM - 8 AM': false,
            '8 AM - 8.30 AM': false,
            '8.30 AM - 9 AM': false,
            '9 AM - 9.30 AM': false,
            '9.30 AM - 10 AM': false,
            '10 AM - 10.30 AM': false,
            '10.30 AM - 11 AM': false,
            '11 AM - 11.30 AM': false,
            '11.30 AM - 12 PM': false,
            '12 PM - 12.30 PM': false,
            '12.30 PM - 1 PM': false,
            '1 PM - 1.30 PM': false,
            '1.30 PM - 2 PM': false,
            '2 PM - 2.30 PM': false,
            '2.30 PM - 3 PM': false,
            '3 PM - 3.30 PM': false,
            '3.30 PM - 4 PM': false,
            '4 PM - 4.30 PM': false,
            '4.30 PM - 5 PM': false,
            '5 PM - 5.30 PM': false,
            '5.30 PM - 6 PM': false,
            '6 PM - 6.30 PM': false,
            '6.30 PM - 7 PM': false,
            '7 PM - 7.30 PM': false,
            '7.30 PM - 8 PM': false,
            '8 PM - 8.30 PM': false,
            '8.30 PM - 9 PM': false,
            '9 PM - 9.30 PM': false,
            '9.30 PM - 10 PM': false,
            '10 PM - 10.30 PM': false,
            '10.30 PM - 11 PM': false,
            '11 PM - 11.30 PM': false,
            '11.30 PM - 12 AM': false
        };
        return timeSlots;
    }

    private isAllSlotsMarked(): boolean {
        let markAllStatus = true;
        Object.keys(this.availability.availableTimeSlot).map((key) => {
            if (!this.availability.availableTimeSlot[key]) {
                markAllStatus = false;
                return;
            }
        });
        return markAllStatus;
    }

    onClickTimeSlot(slot: any): void {
        this.availability.availableTimeSlot[slot] =  !this.availability.availableTimeSlot[slot];
        if (!this.availability.availableTimeSlot[slot]){
            this.markAll = false;
        } else {
            this.markAll = this.isAllSlotsMarked();
        }
    }

    sortTimeSlot(): any{
        return 0;
    }

    onClickSaveButton(): void {
        if (this.availability.availableTimeSlot){
            this.availability.markedAsAvailable = false;
            Object.keys(this.availability.availableTimeSlot).map((key) => {
                if (this.availability.availableTimeSlot[key]) {
                    this.availability.markedAsAvailable = true;
                    return;
                }
            });
        }
        this._calendarService.saveAvailability(this.nurseId, this.availability)
            .subscribe(data => {
                this.matDialogRef.close();
                this._matSnackBar.open('Availability Saved', null, {duration: 3000});
            }, error => console.log(error));
    }

    createEventForm(): FormGroup
    {
        return new FormGroup({
            title : new FormControl(this.event.title),
            start : new FormControl(this.event.start),
            end   : new FormControl(this.event.end),
            allDay: new FormControl(this.event.allDay),
            color : this._formBuilder.group({
                primary  : new FormControl(this.event.color.primary),
                secondary: new FormControl(this.event.color.secondary)
            }),
            meta  :
                this._formBuilder.group({
                    location: new FormControl(this.event.meta.location),
                    notes   : new FormControl(this.event.meta.notes)
                })
        });
    }
}
