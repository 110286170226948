import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Dashboard',
        type     : 'group',
        children : [
            {
                id       : 'appointments',
                title    : 'Appointments',
                type     : 'collapsable',
                icon     : 'email',
                children : [
                    {
                        id        : 'join-consultation',
                        title     : 'Join Consultation',
                        type      : 'item',
                        url       : 'join-consultation',
                        exactMatch: true
                    },
                    {
                        id        : 'old-appointments',
                        title     : 'Upcoming Appointments',
                        type      : 'item',
                        url       : 'upcoming-appointment',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'prescriptions',
                title    : 'Prescriptions',
                icon     : 'email',
                type     : 'collapsable',
                children : [
                    {
                        id        : 'unfinished-prescriptions',
                        title     : 'Pending Prescriptions',
                        type      : 'item',
                        url       : 'pending-prescription',
                        exactMatch: true
                    },
                    {
                        id        : 'finished-prescriptions',
                        title     : 'Finished Prescriptions',
                        type      : 'item',
                        url       : 'finished-prescription',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'your-availability',
                title    : 'Your Availability',
                type     : 'item',
                icon     : 'email',
                url      : '/availabilities'
            },
            {
                id       : 'profile',
                title    : 'Profile',
                translate: 'NAV.PROFILE.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/profile'
            }
        ]
    }
];
