import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class AppointmentService {
  apiUrl = environment.api;
  routeParams: any;
  product: any;
  onProductChanged: BehaviorSubject<any>;

  reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  constructor(private _httpClient: HttpClient) {
      this.onProductChanged = new BehaviorSubject({});
  }

  getAppointmentDetail(appointmentId: number): Observable<any> {
    return this._httpClient.get(`${this.apiUrl}/nurse/appointment/${appointmentId}`, {headers: this.reqHeader});
  }

  getLatestAppointments(nurseId: number): Observable<any>{
      return this._httpClient.get(`${this.apiUrl}/nurse/${nurseId}/appointment/latest`, {headers: this.reqHeader});
  }

  getUpcomingAppointments(nurseId: number): Observable<any>{
      return this._httpClient.get(`${this.apiUrl}/nurse/${nurseId}/appointment/upcoming`, {headers: this.reqHeader});
  }

  getPatientDetail(patientId: number): Observable<any> {
      return this._httpClient.get(`${this.apiUrl}/nurse/patient/${patientId}`, {headers: this.reqHeader});
  }

  savePrescription(prescription: any): Observable<any>{
      return this._httpClient.post(`${this.apiUrl}/nurse/prescription`, prescription, {headers: this.reqHeader});
  }

  updateAppointment(nurseId: number, appointment: any): Observable<any> {
      return this._httpClient.put(`${this.apiUrl}/nurse/${nurseId}/appointment`, appointment, {headers: this.reqHeader});
  }
}
