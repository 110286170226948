import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class PrescriptionService {
  apiUrl = environment.api;
  reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  constructor(private _httpClient: HttpClient) {}

  getPendingPrescription(nurseId: number): Observable<any>{
      return this._httpClient.get(`${this.apiUrl}/nurse/${nurseId}/prescription/pending`, {headers: this.reqHeader});
  }

  getFinishedPrescription(nurseId: number): Observable<any> {
      return this._httpClient.get(`${this.apiUrl}/nurse/${nurseId}/prescription/finished`, {headers: this.reqHeader});
  }

  getPresriptionDetail(presriptionId: number): Observable<any> {
      return this._httpClient.get(`${this.apiUrl}/nurse/prescription/${presriptionId}`, {headers: this.reqHeader});
  }

  savePrescription(prescription: any): Observable<any>{
      return this._httpClient.post(`${this.apiUrl}/nurse/prescription`, prescription, {headers: this.reqHeader});
  }

  sendPrescriptionSubmissionEmailToAdmin(id: number): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/nurse/prescription/${id}/admin/email`, {}, {headers: this.reqHeader});
  }
}
