import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PrescriptionService } from '../service/prescription.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { PrescriptionDetailComponent } from '../prescription-detail/prescription-detail.component';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-finished-prescription',
  templateUrl: './finished-prescription.component.html',
  styleUrls: ['./finished-prescription.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class FinishedPrescriptionComponent implements OnInit {
  nurseId: number;
  prescriptions: any[];
  loading: boolean;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'created_at', 'dateExpires', 'dateRenewalAllowed', 'prescriptionStatus'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private _prescriptionService: PrescriptionService,
              private _matDialog: MatDialog) {
                // Assign the data to the data source for the table to render
                this.dataSource = new MatTableDataSource(this.prescriptions);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              }

  ngOnInit(): void {
    this.nurseId = parseInt(localStorage.getItem('nurseId'), 10);
    if (!this.nurseId){
      // redirect to login page
    } else {
      this.loading = true;
      this._prescriptionService.getFinishedPrescription(this.nurseId)
        .subscribe((data: any) => {
          this.loading = false;
          this.prescriptions = data.prescriptions;
          this.dataSource.data = this.prescriptions;
        }, error => console.log(error));
    }
  }

  onClickPrescriptionDetail(prescription: any): void {
    this._matDialog.open(PrescriptionDetailComponent, {
        panelClass: 'prescription-detail-dialog',
        width: '600px',
        data: {
            nurseId: this.nurseId,
            prescription: prescription
        }
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPrescriptionStatus(id: any): string{
    if (id === 1) { return 'Pending'; }
    else if (id === 2) { return 'Approved'; }
    else if (id === 3) { return 'Rejected'; }
  }
}
