import { Component, OnInit } from '@angular/core';
import { PrescriptionService } from '../service/prescription.service';
import { MatDialog } from '@angular/material';
import { PrescriptionDetailComponent } from '../prescription-detail/prescription-detail.component';
import { Prescription } from '../model/prescription.model';

@Component({
  selector: 'app-pending-prescription',
  templateUrl: './pending-prescription.component.html',
  styleUrls: ['./pending-prescription.component.scss']
})
export class PendingPrescriptionComponent implements OnInit {
  nurseId: number;
  prescriptions: any[];
  loading: boolean;
  constructor(private _prescriptionService: PrescriptionService,
              private _matDialog: MatDialog) { }

  ngOnInit(): void {
    this.nurseId = parseInt(localStorage.getItem('nurseId'), 10);
    if (!this.nurseId){
      // redirect to login page
    } else {
      this.loadPendingPrescriptions();
    }
  }

  onClickPrescriptionDetail(prescription: Prescription): void {
    const prescriptionDetailComponet = this._matDialog.open(PrescriptionDetailComponent, {
        panelClass: 'prescription-detail-dialog',
        width: '600px',
        data: {
            prescription: prescription
        }
    });
    prescriptionDetailComponet.afterClosed()
      .subscribe((data) => {
        this.loadPendingPrescriptions();
      });
  }

  private loadPendingPrescriptions(): void {
    this.loading = true;
      this._prescriptionService.getPendingPrescription(this.nurseId)
        .subscribe((data: any) => {
          this.loading = false;
          this.prescriptions = data.prescriptions;
        }, error => console.log(error));
  }

}
