import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AppointmentService } from '../service/appointment.service';
import { ConfirmationDialogComponent } from 'app/main/shared/confirmation-dialog/confirmation-dialog.component';
import { Prescription, PrescriptionStatus } from '../model/prescription.model';
import { Appointment, AppointmentStatus } from '../model/appointment.model';
import { PrescriptionService } from '../service/prescription.service';
import { Patient } from '../model/patient.model';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';

@Component({
    selector   : 'join-consultation',
    templateUrl: './join-consultation.component.html',
    styleUrls  : ['./join-consultation.component.scss'],
    providers: [],
})
export class JoinConsultationComponent implements OnInit
{
    showVideo: boolean;
    nurseId: number;
    activeAppointments: Appointment[];
    activeAppointment: Appointment;
    activePatient: Patient;
    activePrescription: Prescription;
    loading: boolean;

    constructor(private _appointmentService: AppointmentService,
                private _matDialog: MatDialog,
                private _matSnackBar: MatSnackBar,
                private _prescriptionService: PrescriptionService){}

    ngOnInit(): void {
        this.showVideo = false;
        this.nurseId = parseInt(localStorage.getItem('nurseId'), 10);
        if (this.nurseId){
          this.loadLatestAppointments();
        }
    }

    onClickJoinCall(appointment: Appointment): void {
      // create a prescription for this user and make it active prescription
      this.loading = true;
      const prescription = new Prescription();
      prescription.patientId = appointment.patientId;
      prescription.appointmentId = appointment.id;
      prescription.nurseId = this.nurseId;
      prescription.prescriptionStatus = PrescriptionStatus.pending;
      this._prescriptionService.savePrescription(prescription)
        .subscribe((data: any) => {
          this.activePrescription = data.prescription;
          this._appointmentService.getPatientDetail(appointment.patientId)
          .subscribe((result: any) => {
            this.activePatient = result.patient;
            this.activeAppointment = appointment;
            this.loading = false;
            this.showVideo = true;
          }, error => console.log(error));
        });
    }
    
    onClickCloseCall(): void {
      const confirmationDialogRef = this._matDialog.open(ConfirmationDialogComponent, {
        width: '600px',
        data: {
          message: 'Are you sure you want to leave the meeting ?'
        }
      });
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result){
          this.showVideo = false;
          this.loading = true;
          // update prescription
          this._prescriptionService.savePrescription(this.activePrescription)
            .subscribe((data: any) => {
              this._matSnackBar.open('The prescription is saved and in pending status now. Please review and submit it again for approval', null, {duration: 5000});
            });
          // update appointment
          this.activeAppointment.appointmentStatus = AppointmentStatus.completed;
          this._appointmentService.updateAppointment(this.nurseId, this.activeAppointment)
          .subscribe(() => {
            this.loadLatestAppointments();
            this.loading = false;
          });
          
        }
      });
    }

    private loadLatestAppointments(): void{
      this._appointmentService.getLatestAppointments(this.nurseId)
          .subscribe((data: any) => {
            this.activeAppointments = data.appointments;
          }, error => console.log(error));
    }
 
    onSubmit(): void {
      
    }

    onClickPatientDetail(patientId: number): void {
      this._matDialog.open(PatientDetailComponent, {
          panelClass: 'patient-detail-dialog',
          width: '600px',
          data: {
              patientId: patientId
          }
      });
    }
  }
