import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nurse } from '../e-commerce/model/nurse.model';
import { environment } from 'environments/environment';

@Injectable()
export class ProfileService
{
    apiUrl = environment.api;
    routeParams: any;
    product: any;

    private updatedNurse = new BehaviorSubject<Nurse>(null);
    updatedNurse$ = this.updatedNurse.asObservable();

    reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    updateNurse(nurse: Nurse): void {
        this.updatedNurse.next(nurse);
    }

    constructor( private _httpClient: HttpClient) {}

    getNurseDetail(id: number): Observable<any>{
        return this._httpClient.get(`${this.apiUrl}/nurse/${id}/getProfileInfo`, {headers: this.reqHeader});

    }

    saveNurseDetail(nurse: Nurse): Observable<any> {
        return this._httpClient.post(`${this.apiUrl}/nurse/${nurse.id}/saveProfileInfo`, nurse, {headers: this.reqHeader});
    }
}
