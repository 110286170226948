import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// tslint:disable-next-line:max-line-length
import { MatButtonModule, MatDividerModule, MatIconModule, MatTabsModule, MatChipsModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatRippleModule, MatSelectModule, MatSortModule, MatSnackBarModule, MatTableModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { ProfileComponent } from './profile.component';
import { ProfileService } from './profile.service';
import { FuseWidgetModule } from '@fuse/components';

const routes = [
    {
        path     : 'profile',
        component: ProfileComponent
    }
];

@NgModule({
    declarations: [
        ProfileComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,

        FuseSharedModule,
        FuseWidgetModule
    ],
    providers   : [
        ProfileService
    ]
})
export class ProfileModule
{
}
