import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ProfileService } from '../profile/profile.service';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';

@Component({
    selector     : 'login-2',
    templateUrl  : './login-2.component.html',
    styleUrls    : ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Login2Component implements OnInit
{
    loginForm: FormGroup;
    apiUrl = environment.api
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _http: HttpClient,
        private _router: Router,
        private _profileService: ProfileService,
        private _matSnackBar: MatSnackBar
    )
    {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void
    {
        localStorage.removeItem('token');
        localStorage.removeItem('nurseId');
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    onSubmitLoginForm(): void {
        const data: any = {
            email: this.loginForm.controls.email.value,
            password: this.loginForm.controls.password.value
        };
        this._http.post(`${this.apiUrl}/nurse/auth/login`, data)
            .subscribe( (result: any) => {
                if (result && result.error) {
                    this._matSnackBar.open(result.error, null, {duration: 3000, panelClass: 'mat-snack-danger'});
                    return;
                }
                localStorage.setItem('token', result.token);
                localStorage.setItem('nurseId', result.nurse.id);
                this._profileService.updateNurse(result.nurse);
                this._router.navigateByUrl(`profile`);
            }, error => console.log(error));
    }

    onClickForgotPassword(): void {
        this._matSnackBar.open('Please contact your admin to get your updated password', null, {duration: 5000});
    }
}
