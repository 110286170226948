import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ProfileService } from './profile.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Nurse } from '../e-commerce/model/nurse.model';

@Component({
    selector     : 'profile',
    templateUrl  : './profile.component.html',
    styleUrls    : ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ProfileComponent implements OnInit {

    nurse: Nurse;
    nurseId: number;
    constructor(private _profileService: ProfileService,
                private _matSnackBar: MatSnackBar,
                private _activatedRoute: ActivatedRoute,
                private _router: Router) {
        this.nurse = new Nurse();
    }

    ngOnInit(): void
    {
        this.nurseId = parseInt(localStorage.getItem('nurseId'), 10);
        if (this.nurseId){
            this._profileService.getNurseDetail(this.nurseId)
                .subscribe(nurse => {
                    if (nurse) {
                        this.nurse = nurse;
                    }
                }, error => {
                    // this._router.navigate(['/auth/login']);
                    console.log(error);
                });
        } else {
            console.log('error');
            // this._router.navigate(['/auth/login']);
        }
    }

    onClickSave(): void {
        this._profileService.saveNurseDetail(this.nurse)
            .subscribe((response: any) => {
                this._matSnackBar.open('Profile Saved', null, {duration: 3000});
                console.log(response);
            }, (error: any) => console.log(error));
    }
  
  }
