import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PatientService } from '../../service/patient.service';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss']
})
export class PatientDetailComponent implements OnInit {

  dialogTitle = 'Patient Detail';
  patient: any;
  patientId: number;
  loading: boolean;
  constructor(private _matDialogRef: MatDialogRef<PatientDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _patientService: PatientService) {
    this.patientId = _data.patientId;
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.patientId){
      this._patientService.getPatientDetail(this.patientId)
        .subscribe((data: any) => {
          this.patient = data.patient;
          this.loading = false;
        });
    }
  }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

}
