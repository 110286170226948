import { Component, OnInit, Inject } from '@angular/core';
import { Appointment } from '../model/appointment.model';
import { Patient } from '../model/patient.model';
import { AppointmentService } from '../service/appointment.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss']
})
export class AppointmentDetailComponent implements OnInit {

  dialogTitle = 'Appointment Detail';
  patient: Patient;
  appointment: Appointment;
  appointmentId: number;
  loading: boolean;
  constructor(private _matDialogRef: MatDialogRef<AppointmentDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _appointmentService: AppointmentService) {
    this.appointmentId = _data.appointmentId;
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.appointmentId){
      this._appointmentService.getAppointmentDetail(this.appointmentId)
        .subscribe((data: any) => {
          this.appointment = data.appointment;
          this.patient = data.patient;
          this.loading = false;
        });
    }
  }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

}
