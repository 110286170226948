export class Prescription {
    id: number;
    prescriberProvince?: string;
    prescriberNotes?: string;
    dateRenewalAllowed?: Date;
    dateExpires?: Date;
    prescriptionStatus?: number;
    producer?: string;
    prescriptionImageUrl?: string;
    completedByNurse?: boolean;
    completedByAdmin?: boolean;
    patientId: number;
    nurseId: number;
    appointmentId: number;
    created_at: Date;
    updated_at: Date;
}

export enum PrescriptionStatus {
    pending,
    completedByNurse,
    accepted,
    rejected
}