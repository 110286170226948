import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AppointmentService } from '../service/appointment.service';
import { Appointment } from '../model/appointment.model';
import { Router } from '@angular/router';
import { AppointmentDetailComponent } from '../appointment-detail/appointment-detail.component';

@Component({
  selector: 'app-upcoming-appointment',
  templateUrl: './upcoming-appointment.component.html',
  styleUrls: ['./upcoming-appointment.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class UpcomingAppointmentComponent implements OnInit {
  nurseId: number;
  appointments: Appointment[];
  loading: boolean;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'appointmentNo', 'created_at', 'appointmentDate', 'appointmentTimeSlot'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private _appointmentService: AppointmentService,
              private _matDialog: MatDialog,
              private _router: Router) {
                // Assign the data to the data source for the table to render
                this.dataSource = new MatTableDataSource(this.appointments);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              }

  ngOnInit(): void {
    this.nurseId = parseInt(localStorage.getItem('nurseId'), 10);
    if (!this.nurseId){
      this._router.navigate(['auth/login']);
    } else {
      this.loading = true;
      this._appointmentService.getUpcomingAppointments(this.nurseId)
        .subscribe((data: any) => {
          this.loading = false;
          this.appointments = data.appointments;
          this.dataSource.data = this.appointments;
        }, error => console.log(error));
    }
  }

  onClickAppointmentDetail(appointment: Appointment): void {
    this._matDialog.open(AppointmentDetailComponent, {
        panelClass: 'appointment-detail-dialog',
        width: '600px',
        data: {
            nurseId: this.nurseId,
            appointmentId: appointment.id
        }
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
