import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';

const moment = _moment;

export const MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'LL',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

@Component({
    selector   : 'book-appointment',
    templateUrl: './book-appointment.component.html',
    styleUrls  : ['./book-appointment.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class BookAppointmentComponent implements OnInit
{
    bookAppointmentForm: FormGroup;
    availableTime: string[] = ['1 AM - 1.30 AM', '1.30 AM - 2 AM', '2 AM - 2.30 AM', '2.30 AM - 3 AM'];
    searchItems: string[] = ['Nurse One', 'Nurse two', 'Nurse three', 'Nurse four', 'Nurse five', 'Nurse six'];
    constructor(private _formBuilder: FormBuilder){}

    ngOnInit(): void {
        this.bookAppointmentForm = this._formBuilder.group({
            appointmentDate: ['', Validators.required],
            appointmentTime: ['', Validators.required]

        });
    }
 
    onSubmit(): void {
      console.log(this.bookAppointmentForm.get('appointmentDate'));
      console.log(this.bookAppointmentForm.get('appointmentTime'));
    }
    
    
}
